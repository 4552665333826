<template>
  <van-overlay :show="!!loading">
    <div class="loading-wrapper">
      <van-loading size="40"></van-loading>
    </div>
  </van-overlay>

  <router-view />
</template>

<script>
import { Loading, Overlay } from 'vant';
import { mapState } from 'vuex';

export default {
  name: 'App',

  computed: {
    ...mapState(['loading']),
  },

  components: {
    [Loading.name]: Loading,
    [Overlay.name]: Overlay,
  },
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.van-overlay {
  z-index: 99;
}
</style>

<style lang="scss" src="@/assets/styles/global.scss"></style>
